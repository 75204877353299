import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
    name: 'userData',
    initialState: {
        user: null,
        token: null,
        locationPicked: null,
        role: null,
        transastionId: null,
        setPaidUsedData: null
    },
    reducers: {
        login: (state, action) => {
            state.user = action.payload
        },
        storeToken: (state, action) => {
            state.token = action.payload
        },
        setLocationPicked: (state, action) => {
            state.locationPicked = action.payload
        },
        setRole: (state, action) => {
            state.role = action.payload
        },
        setTransactionId: (state, action) => {
            state.transastionId = action.payload
        },
        setDataPaidUserData: (state, action) => {
            state.setPaidUsedData = action.payload
        },
        logout: (state) => {
            state.user = null
            state.token = null
            state.locationPicked = null
            state.role = null
            state.transastionId = null
            state.setPaidUsedData = null
        },
    }
});

export const userReducer = userSlice.reducer;

export const { login, logout, storeToken, setLocationPicked, setRole, setTransactionId, setDataPaidUserData } = userSlice.actions;
