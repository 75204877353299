/* eslint-disable no-restricted-globals */
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Select from 'react-select'
import Swal from 'sweetalert2';
import { DataOption } from '../../interfaces';
import { RootState } from '../../store/store';

type formType = {
    branch: string
    product_id: string
}

export const EnDisProduct = () => {
    const globalStateUser = useSelector((state: RootState) => {
        return state.userData
    });
    const token = globalStateUser.token;
    const { register, handleSubmit, watch, formState: { errors }, control } = useForm<formType>();
    const [disabledProducts, setDisabledProducts] = useState([]);
    const [options, setOptions] = useState<DataOption[]>([]);
    const [optionsBranch, setOptionsBranch] = useState<DataOption[]>([]);

    useEffect(() => {
        axios.get(
            (process.env.REACT_APP_GET_ALL_PRODUCT as string),
            {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }
        )
            .then(response => {
                response.data?.data?.forEach((data: any) => {
                    setOptions(prevState => [...prevState, {
                        value: data.id,
                        label: data.name
                    }])
                })
            })
            .catch(error => {
                console.log(error);
            });

        axios.get(
            (process.env.REACT_APP_BRANCH as string),
            {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }
        )
            .then(response => {
                response.data?.data?.forEach((data: any) => {
                    setOptionsBranch(prevState => [...prevState, {
                        value: data.id,
                        label: data.name
                    }])
                })
            })
            .catch(error => {
                console.log(error);
            });

        axios.get(
            (process.env.REACT_APP_ENDISPRODUCT as string),
            {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }
        )
            .then(response => {
                setDisabledProducts(response.data.data)
            })
            .catch(error => {
                console.log(error);
            });
    }, []);

    const onSubmit: SubmitHandler<formType> = data => {
        axios.post(
            (process.env.REACT_APP_ENDISPRODUCT as string),
            data,
            {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }
        )
            .then(response => {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Data telah di tambahkan!',
                    showConfirmButton: false,
                    timer: 1500
                })
                location.reload();
            })
            .catch(error => {
                console.log(error);
            });
    };

    const handleDelete = (data: any) => {
        Swal.fire({
            title: 'Do you want to dalete data?',
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: 'Save',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                axios.delete(
                    (process.env.REACT_APP_ENDISPRODUCT as string) + `/${data.id}`,
                    {
                        headers: {
                            "Authorization": `Bearer ${token}`
                        }
                    }
                )
                    .then(response => {
                        Swal.fire('Deleted!', '', 'success')
                        location.reload();
                    })
                    .catch(error => {
                        console.log(error)
                    })
            } else if (result.isDenied) {
                Swal.fire('Changes are not saved', '', 'info')
            }
        })
    }

    return (
        <>
            <div className='border border-white bg-white rounded-md p-3 shadow-md mb-5'>
                <div className='flex justify-center my-5'>
                    <h3 className='font-bold text-xl'>Add Disabled Product</h3>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='form-control'>
                        <Controller
                            control={control}
                            name='branch'
                            render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                <Select
                                    value={optionsBranch.find((c) => c.label === value)}
                                    name={name}
                                    options={optionsBranch}
                                    onChange={(selectedOption: any) => {
                                        onChange(selectedOption.value);
                                    }}
                                />
                            )}
                        /><br />
                        <Controller
                            control={control}
                            name='product_id'
                            render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                <Select
                                    value={options.find((c) => c.value === value)}
                                    name={name}
                                    options={options}
                                    onChange={(selectedOption: any) => {
                                        onChange(selectedOption.value);
                                    }}
                                />
                            )}
                        /><br />
                        <button className='btn btn-primary' type='submit'>Add</button>
                    </div>
                </form>
            </div>
            <div className='border border-white bg-white rounded-md p-3 shadow-md'>
                <div className='flex justify-center my-5'>
                    <h3 className='font-bold text-xl'>Disabled Product</h3>
                </div>
                <div className="overflow-y-auto">
                    <table className="table table-zebra w-full">
                        {/* head */}
                        <thead>
                            <tr>
                                <th />
                                <th>Branch</th>
                                <th>Product</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* row 1 */}
                            {
                                disabledProducts.map((data: any, idx: any) => {
                                    return <tr key={idx}>
                                        <th>{idx + 1}</th>
                                        <td>{data.branch}</td>
                                        <td>{data.product.name}</td>
                                        <td><button className='btn btn-error' onClick={() => handleDelete(data)}>Delete</button></td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}
