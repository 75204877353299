import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, Link } from "react-router-dom";
import { RootState } from "../../store/store";
import { logout } from "../../store/userSlice";

export const Drawer = () => {
  const globalStateUser = useSelector((state: RootState) => {
    return state.userData;
  });
  const locationPicked = globalStateUser?.locationPicked;
  const role = globalStateUser?.role;
  const dispatch = useDispatch();

  const locationText = (location: string | null) => {
    if (location === "JTP1") {
      return "Jatim Park 1";
    } else if (location === "JTP2") {
      return "Jatim Park 2";
    } else if (location === "JTP3") {
      return "Jatim Park 3";
    } else if (location === "ECO") {
      return "Eco Green Park";
    } else if (location === "SENYUM") {
      return "Senyum World Hotel";
    } else if (location === "MGG") {
      return "Milenial Glow Garden";
    } else if (location === "PFP") {
      return "Predator Fun Park";
    } else if (location === "POHONINN") {
      return "Pohon Inn Hotel";
    } else if (location === "MA") {
      return "Museum Angkut";
    } else if (location === "BALOGA") {
      return "Batu Love Garden";
    } else {
      return "null";
    }
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <>
      <div className="h-screen drawer drawer-mobile w-full">
        <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
        <div className="flex flex-col drawer-content">
          {/* Page content here */}
          {/* Navbar */}
          <div className="navbar bg-base-100 shadow-xl">
            <div className="navbar-start">
              <div className="dropdown">
                <label
                  htmlFor="my-drawer-2"
                  className="btn btn-ghost btn-circle lg:hidden"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M4 6h16M4 12h16M4 18h7"
                    />
                  </svg>
                </label>
              </div>
            </div>
            <div className="navbar-center">
              <a className="btn btn-ghost normal-case text-xl">
                Warehouse - {locationText(locationPicked)}
              </a>
            </div>
            <div className="navbar-end">
              <button className="btn btn-ghost btn-circle">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </button>
              <button className="btn btn-ghost btn-circle">
                <div className="indicator">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                    />
                  </svg>
                  <span className="badge badge-xs badge-primary indicator-item" />
                </div>
              </button>
            </div>
            <div className="dropdown dropdown-end">
              <label tabIndex={0} className="btn btn-ghost btn-circle avatar">
                <div className="w-10 rounded-full">
                  <img src="https://api.lorem.space/image/face?hash=33791" />
                </div>
              </label>
              <ul
                tabIndex={0}
                className="mt-3 p-2 shadow menu menu-compact dropdown-content bg-base-100 rounded-box w-52"
              >
                <li>
                  <a onClick={handleLogout}>Logout</a>
                </li>
              </ul>
            </div>
          </div>
          {/* Navbar END */}
          <div className="p-5">
            <Outlet />
          </div>
        </div>
        <div className="drawer-side shadow-xl">
          <label htmlFor="my-drawer-2" className="drawer-overlay" />
          <ul className="menu p-4 overflow-y-auto w-80 bg-base-100 text-base-content">
            {/* Sidebar content here */}
            {/* <li><p className='text-2xl font-extrabold text-primary'>JTP DC</p></li> */}
            {/**
             * God Mode Only
             */}
            <li>
              <p className="text-2xl font-extrabold text-primary">
                JTP DC (God Mode)
              </p>
            </li>
            {/**
             * God Mode Only
             */}
            {locationPicked === null ? null : (
              <>
                {/**
                 * God Mode Only
                 */}
                {/* <li><Link to='/inputstock' >Input Stock</Link></li> */}
                {/**
                 * God Mode Only
                 */}

                {/**
                 * Common user
                 */}
                <li>
                  <Link to="/paid">Barang sudah dibayar</Link>
                </li>
                <li>
                  <Link to="/taken">Barang sudah diambil</Link>
                </li>
                {/**
                 * Common user
                 */}
              </>
            )}
            {role === "sudo" ? (
              <>
                {/**
                 * God Mode Only
                 */}
                {/* <li><Link to='/addnewbranch' >Add New Branch</Link></li>
                                    <li><Link to='/addnewproduct' >Add New Product</Link></li> */}
                <li>
                  <Link to="/unpaid">Set Paid</Link>
                </li>
                <li>
                  <Link to="/endisproduct">Enable / Disable Product</Link>
                </li>
                {/**
                 * God Mode Only
                 */}
              </>
            ) : (
              <>
                <li>
                  <Link to="/stock">Stock</Link>
                </li>
                <li>
                  <Link to="/mutasigudang">Mutasi Gudang</Link>
                </li>
                {/* <li><Link to='/mutasitenant'>Mutasi Tenant</Link></li> */}
              </>
            )}
            {/**
             * God Mode Only
             */}
            {/**
             * God Mode Only
             */}
          </ul>
        </div>
      </div>
    </>
  );
};
