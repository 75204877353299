import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../store/store';
import { setTransactionId } from '../../store/userSlice';
import axios from 'axios';
import Swal from 'sweetalert2'

export const EditPaid = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const globalStateUser = useSelector((state: RootState) => {
        return state.userData
    });
    const transactionId: any = globalStateUser?.transastionId;
    const token = globalStateUser.token;

    const rupiahConvert = (intValue: any) => {
        let reverse = intValue.toString().split('').reverse().join(''),
            ribuan = reverse.match(/\d{1,3}/g);
        ribuan = ribuan.join('.').split('').reverse().join('');
        return ribuan;
    }

    useEffect(() => {
        if (!transactionId) {
            navigate('/unpaid');
        }
    }, []);

    useEffect(() => {
        return () => {
            dispatch(setTransactionId(null))
        }
    }, []);

    const onSubmit = handleSubmit(data => {
        const dataPaid = {
            transaction_id: transactionId.id,
            payment_type: "transfer",
            bank: data.bank,
            account_number: data.account_number,
            account_name: data.account_name
        }
        Swal.fire({
            title: `Yakin ingin merubah status ${transactionId.code} ?`,
            text: `Atas nama ${transactionId.user.name} total amount Rp.${rupiahConvert(transactionId.pay_amount)}`,
            // showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            // denyButtonText: `Don't save`,
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                axios.put(
                    (process.env.REACT_APP_TRANSACTION_PAY as string),
                    dataPaid,
                    {
                        headers: {
                            "Authorization": `Bearer ${token}`
                        }
                    }
                )
                    .then(response => {
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: 'Status telah diubah!',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        navigate('/unpaid');
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
            // else if (result.isDenied) {
            //   Swal.fire('Changes are not saved', '', 'info')
            // }
        })
    });

    return (
        <>
            <form onSubmit={onSubmit}>
                <div className="form-control">
                    <label className="label">
                        <span className="label-text">Bank</span>
                    </label>
                    <input type="text" placeholder="bank" className={`${!errors.bank?.type ? 'input input-bordered' : 'input border-2 border-error'}`} {...register('bank', { required: true })} />
                    <div className="label justify-start">
                        {errors.bank ? <FontAwesomeIcon icon={faTimesCircle} className='text-error mr-2' /> : ""}
                        <span className='text-error text-sm font-bold'>
                            {errors.bank?.type === "required" && "Bank required"}
                        </span>
                    </div>
                </div>
                <div className="form-control">
                    <label className="label">
                        <span className="label-text">Account Number</span>
                    </label>
                    <input placeholder="account_number" className={`${!errors.account_number?.type ? 'input input-bordered' : 'input border-2 border-error'}`} {...register('account_number', { required: true })} />
                    <div className="label justify-start">
                        {errors.account_number ? <FontAwesomeIcon icon={faTimesCircle} className='text-error mr-2' /> : ""}
                        <span className='text-error text-sm font-bold'>
                            {errors.account_number?.type === "required" && "Account Number required"}
                        </span>
                    </div>
                    <label className="label">
                    </label>
                </div>
                <div className="form-control">
                    <label className="label">
                        <span className="label-text">Account Name</span>
                    </label>
                    <input placeholder="account_name" className={`${!errors.account_name?.type ? 'input input-bordered' : 'input border-2 border-error'}`} {...register('account_name', { required: true })} />
                    <div className="label justify-start">
                        {errors.account_name ? <FontAwesomeIcon icon={faTimesCircle} className='text-error mr-2' /> : ""}
                        <span className='text-error text-sm font-bold'>
                            {errors.account_name?.type === "required" && "Account Name required"}
                        </span>
                    </div>
                    <label className="label">
                    </label>
                </div>
                <div className="form-control mt-6">
                    <button
                        className='btn btn-primary'
                        type='submit'>
                        Sikat Boss!! Hehehe!!!
                    </button>
                </div>
            </form>
        </>
    )
}
