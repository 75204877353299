import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

export const Taken = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const globalStateUser = useSelector((state: RootState) => {
        return state.userData
    });
    const token = globalStateUser.token;
    const [pages, setPages] = useState<any>({});
    const [totalData, setTotalData] = useState({
        from: 0,
        to: 0,
        total: 0
    })

    useEffect(() => {
        axios.get(
            (process.env.REACT_APP_TRANSACTION_TAKEN as string),
            {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }
        )
            .then(response => {
                setTotalData({
                    ...totalData,
                    from: response.data.data.from,
                    to: response.data.data.to,
                    total: response.data.data.total
                })
                setPages(response.data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }, []);

    const prevPage = () => {
        axios.get(
            (process.env.REACT_APP_TRANSACTION_TAKEN as string) + '?page=' + (parseInt(pages?.current_page) - 1),
            {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }
        )
            .then(response => {
                setTotalData({
                    ...totalData,
                    from: response.data.data.from,
                    to: response.data.data.to,
                    total: response.data.data.total
                })
                setPages(response.data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const nextPage = () => {
        if (pages.next_page_url !== null) {
            axios.get(
                (process.env.REACT_APP_TRANSACTION_TAKEN as string) + '?page=' + parseInt(pages?.current_page + 1),
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                }
            )
                .then(response => {
                    setTotalData({
                        ...totalData,
                        from: response.data.data.from,
                        to: response.data.data.to,
                        total: response.data.data.total
                    })
                    setPages(response.data.data);
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    const handleSearch = handleSubmit(data => {
        axios.get(
            (process.env.REACT_APP_SEARCH_TAKEN_TRX as string) + data.trx_id,
            {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }
        )
            .then(response => {
                setTotalData({
                    ...totalData,
                    from: response.data.data.from,
                    to: response.data.data.to,
                    total: response.data.data.total
                })
                setPages(response.data.data);
            })
            .catch(error => {
                console.log(error);
            });
    })

    return (
        <>
            <div className='text-2xl text-center font-bold'>Barang sudah diambil</div><br />
            <form onSubmit={handleSearch}>
                <div className='flex justify-end mb-3 items-center'>
                    <div className='flex flex-col md:flex-row item-center'>
                        <div className='w-64 mx-2'>
                            <input type="text" placeholder="Trx id..." className="input input-bordered w-full max-w-xs" {...register('trx_id')} />
                        </div>
                        <div>
                            <button className='btn btn-primary'>Search</button>
                        </div>
                    </div>
                </div>
            </form>
            <div>
                <div className="overflow-x-auto">
                    <table className="table table-compact w-full">
                        <thead>
                            <tr>
                                <th />
                                <th>Taken Id</th>
                                <th>Tanggal Taken</th>
                                <th>Toko</th>
                                <th>Trx Id</th>
                                {/* <th>Barang</th> */}
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                pages.data?.map((data: any, idx: any) => {
                                    return (
                                        <tr key={idx}>
                                            <th>{idx + 1}</th>
                                            <td>{data?.taken_code}</td>
                                            <td>{data?.taken_date_format}</td>
                                            <td>{data?.shop.name}</td>
                                            <td>{data?.code}</td>
                                            {/* <td>{data?.items?.map((data: any, idx: any) => {
                                            return <p key={idx}>{data.product.name}</p>
                                        })}</td> */}
                                            <td>
                                                <a className='btn btn-info' href={`${process.env.REACT_APP_INVOICE}${data?.id}&mode=view`} target='_blank'>View</a>&nbsp;&nbsp;&nbsp;
                                                {/* <a className='btn bg-blue-400 border-0 text-black' onClick={() => { handleToken(data?.id) }}>Token</a>&nbsp;&nbsp;&nbsp; */}
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='flex flex-col md:flex-row justify-between items-center'>
                <div className='mb-2 md:mb-0'>
                    <p>Showing {totalData.from} to {totalData.to} of {totalData.total} entries</p>
                </div>
                <div>
                    <div className="btn-group float-right mt-5">
                        <button className={pages.prev_page_url === null ? `btn btn-disabled` : `btn btn-primary`} onClick={prevPage}>«</button>
                        <button className="btn btn-primary">Page {pages?.current_page}</button>
                        <button className={pages.next_page_url === null ? `btn btn-disabled` : `btn btn-primary`} onClick={nextPage}>»</button>
                    </div>
                </div>
            </div>
        </>
    )
}
