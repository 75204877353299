import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

export const Stock = () => {
    const globalStateUser = useSelector((state: RootState) => {
        return state.userData
    });
    const token = globalStateUser.token;
    const locationPicked = globalStateUser?.locationPicked;
    const [stock, setStock] = useState([]);

    useEffect(() => {
        axios.get(
            (process.env.REACT_APP_TRANSACTION_STOCK as string),
            {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }
        )
            .then(response => {
                setStock(response.data.data)
            })
            .catch(error => {
                console.log(error);
            });
    }, []);

    return (
        <>
            <div className='text-2xl text-center font-bold'>Stocks</div><br />
            <div className='flex flex-col md:flex-row justify-between'>
                <div>
                    <a href={
                        locationPicked === null ?
                            `${process.env.REACT_APP_STOCKS}`
                            :
                            `${process.env.REACT_APP_STOCKS}?branch=${locationPicked}`
                    } target='_blank' className='btn btn-primary'>View Stock</a>
                </div>
                <div>
                    <a href={
                        locationPicked === null ?
                            `${process.env.REACT_APP_EXPORT_STOCK}`
                            :
                            `${process.env.REACT_APP_EXPORT_STOCK}${locationPicked}`
                    } target='_blank' className='btn bg-green-700 border-0 text-white'>Export Excell</a>
                </div>
            </div>
            <br />
            <div className="overflow-x-auto">
                <table className="table table-compact w-full">
                    <thead>
                        <tr>
                            <th />
                            <th>Name</th>
                            <th>Stock</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            stock?.map((data: any, idx: any) => {
                                return (
                                    <tr key={idx}>
                                        <th>{idx + 1}</th>
                                        <td>{data?.name}</td>
                                        <td>{data?.total}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}
