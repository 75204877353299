import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { TableMutasi } from "../../components/TableMutasi/TableMutasi";
import { DataOption } from "../../interfaces";
import { RootState } from "../../store/store";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";

export const MutasiGudang = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm();
  const globalStateUser = useSelector((state: RootState) => {
    return state.userData;
  });
  const token = globalStateUser.token;
  const location = globalStateUser.locationPicked;
  const [dataMutasi, setDataMutasi] = useState([]);
  const [options, setOptions] = useState<DataOption[]>([]);
  const [productId, setProductId] = useState("");
  const [date, setDate] = useState({
    startDate: "",
    endDate: "",
  });

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_LIST_PRODUCT_MUTATION_WAREHOUSE as string, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        response.data?.data?.forEach((data: any) => {
          setOptions((prevState) => [
            ...prevState,
            {
              value: data.id,
              label: data.name,
            },
          ]);
        });
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(
        (process.env.REACT_APP_MUTATION_WAREHOUSE as string) +
          `?product_id=${productId}&start=${date.startDate}&end=${date.endDate}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setDataMutasi(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [productId, date.endDate]);

  const getExcel = handleSubmit((data) => {
    axios
      .get(
        (process.env.REACT_APP_EXPORT_WARHOUSE as string) +
          `?branch=${location}&product_id=${data.product_id}&start=${date.startDate}&end=${date.endDate}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        window.open(
          (process.env.REACT_APP_EXPORT_WARHOUSE as string) +
            `?branch=${location}&product_id=${data.product_id}&start=${date.startDate}&end=${date.endDate}`,
          "_blank"
        );
      })
      .catch((error) => {
        console.log(error);
      });
  });

  return (
    <>
      <div className="text-2xl text-center font-bold clear-both">
        Mutasi Gudang
      </div>
      <br />
      <div className="flex flex-col justify-between items-center md:items-start md:flex-row gap-3 mb-4 md:mb-0 ml-0 md:ml-10">
        <div className="basis-1/4">
          <label className="label">
            <span className="label-text">Pilih Produk</span>
          </label>
          <div className="w-60 mb-5 mr-3">
            <Controller
              control={control}
              name="product_id"
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <Select
                  value={options.find((c) => c.value === value)}
                  name={name}
                  options={options}
                  onChange={(selectedOption: any) => {
                    setProductId(selectedOption.value);
                    onChange(selectedOption.value);
                  }}
                />
              )}
            />
          </div>
        </div>
        <div className="form-control w-full basis-1/4">
          <label className="label">
            <span className="label-text">Start Date</span>
          </label>
          <Controller
            control={control}
            name="date"
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <input
                disabled={productId === ""}
                type="date"
                className="border rounded-md p-[5px] border-slate-300"
                onChange={(e: any) => {
                  setDate({ ...date, startDate: e.target.value });
                }}
              />
            )}
          />
        </div>
        <div className="form-control w-full basis-1/4">
          <label className="label">
            <span className="label-text">End Date</span>
          </label>
          <Controller
            control={control}
            name="date"
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <input
                disabled={date.startDate === ""}
                type="date"
                className="border rounded-md p-[5px] border-slate-300"
                onChange={(e: any) => {
                  setDate({ ...date, endDate: e.target.value });
                }}
              />
            )}
          />
        </div>
        <div className="basis-1/4">
          <label className="label mt-3">
            <span className="label-text"></span>
          </label>
          <button className="btn bg-green-700 border-0" onClick={getExcel}>
            Export Excel
          </button>
        </div>
      </div>
      <div className="clear-both">
        <TableMutasi data={dataMutasi} />
      </div>
    </>
  );
};
