import React from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { TableProps } from '../../interfaces'
import { setTransactionId } from '../../store/userSlice'

export const TableUnpaid = ({ data }: TableProps) => {
    const dispatch = useDispatch();

    return (
        <>
            <div className="overflow-x-auto">
                <table className="table table-compact w-full">
                    <thead>
                        <tr>
                            <th />
                            <th>Tr-ID</th>
                            <th>Tanggal Transaksi</th>
                            <th>Toko</th>
                            <th>Email</th>
                            {/* <th>Barang</th> */}
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data?.map((data: any, idx: any) => {
                                return (
                                    <tr key={idx}>
                                        <th>{idx + 1}</th>
                                        <td>{data?.code}</td>
                                        <td>{data?.order_date}</td>
                                        <td>{data?.shop.name}</td>
                                        <td>{data?.user.email}</td>
                                        {/* <td>{data?.items?.map((data: any, idx: any) => {
                                            return <p key={idx}>{data.product.name}</p>
                                        })}</td> */}
                                        <td>
                                            <Link to='/setpaid' className='btn btn-primary' onClick={() => { dispatch(setTransactionId(data)) }}>Set Paid</Link>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}
