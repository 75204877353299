import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { TableUnpaid } from '../../components/TableUnpaid/TableUnpaid'
import { RootState } from '../../store/store';

export const Unpaid = () => {
    const globalStateUser = useSelector((state: RootState) => {
        return state.userData
    });
    const token = globalStateUser.token;
    const [dataUnpaid, setDataUnpaid] = useState([]);

    useEffect(() => {
        axios.get(
            (process.env.REACT_APP_TRANSACTION_UNPAID as string),
            {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }
        )
            .then(response => {
                setDataUnpaid(response.data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }, []);

    return (
        <>
            <div className='text-2xl text-center font-bold'>Barang belum dibayar</div><br />
            <TableUnpaid data={dataUnpaid} />
        </>
    )
}
