/* eslint-disable no-restricted-globals */
import axios from 'axios'
import React from 'react'
import { useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import { TableProps } from '../../interfaces'
import { RootState } from '../../store/store'

export const Table = ({ data }: TableProps) => {
    const globalStateUser = useSelector((state: RootState) => {
        return state.userData
    });
    const token = globalStateUser.token;

    const handleToken = (id: any) => {
        Swal.fire({
            title: 'Pengambilan Barang',
            // text: `Anda Mengedit ${product.name} pada ${data.branch} Pada week ${data.week_start} - ${data.week_end}`,
            html:
                `Masukkan Token Pengambilan`,
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Submit',
            showLoaderOnConfirm: true,
            // inputValue: product.total,
            preConfirm: (submitChanges) => {
                const changeData = {
                    transaction_id: id,
                    token_code: submitChanges.toUpperCase(),
                }
                return axios.post(
                    (process.env.REACT_APP_TAKEN as string),
                    changeData,
                    {
                        headers: {
                            "Authorization": `Bearer ${token}`
                        }
                    }
                ).then(response => {
                    if (response.data.message === "invalid token") {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: `${response.data.message}`,
                        })
                    } else {
                        location.reload();
                        Swal.fire({
                            title: `Data Telah Diambil!`
                        })
                    }
                })
                    .catch(error => {
                        console.log(error)
                    })
            },
            allowOutsideClick: () => !Swal.isLoading()
        })
    }

    return (
        <>
            <div className="overflow-x-auto">
                <table className="table table-compact w-full">
                    <thead>
                        <tr>
                            <th />
                            <th>Tr-ID</th>
                            <th>Tanggal Transaksi</th>
                            <th>Toko</th>
                            <th>Email</th>
                            {/* <th>Barang</th> */}
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data?.map((data: any, idx: any) => {
                                return (
                                    <tr key={idx}>
                                        <th>{idx + 1}</th>
                                        <td>{data?.code}</td>
                                        <td>{data?.order_date}</td>
                                        <td>{data?.shop.name}</td>
                                        <td>{data?.user.email}</td>
                                        {/* <td>{data?.items?.map((data: any, idx: any) => {
                                            return <p key={idx}>{data.product.name}</p>
                                        })}</td> */}
                                        <td>
                                            <a className='btn btn-info' href={`${process.env.REACT_APP_INVOICE}${data?.id}&mode=view`} target='_blank'>View</a>&nbsp;&nbsp;&nbsp;
                                            <a className='btn bg-blue-400 border-0 text-black' onClick={() => { handleToken(data?.id) }}>Token</a>&nbsp;&nbsp;&nbsp;
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}
