import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Table } from '../../components/Table/Table'
import { RootState } from '../../store/store';

export const Paid = () => {
    const globalStateUser = useSelector((state: RootState) => {
        return state.userData
    });
    const token = globalStateUser.token;
    const [pages, setPages] = useState<any>({});
    const [totalData, setTotalData] = useState({
        from: 0,
        to: 0,
        total: 0
    })

    useEffect(() => {
        axios.get(
            (process.env.REACT_APP_TRANSACTION_PAID as string),
            {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }
        )
            .then(response => {
                setTotalData({
                    ...totalData,
                    from: response.data.data.from,
                    to: response.data.data.to,
                    total: response.data.data.total
                })
                setPages(response.data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }, []);

    const prevPage = () => {
        axios.get(
            (process.env.REACT_APP_TRANSACTION_PAID as string) + '?page=' + (parseInt(pages?.current_page) - 1),
            {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }
        )
            .then(response => {
                setTotalData({
                    ...totalData,
                    from: response.data.data.from,
                    to: response.data.data.to,
                    total: response.data.data.total
                })
                setPages(response.data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const nextPage = () => {
        if (pages.next_page_url !== null) {
            axios.get(
                (process.env.REACT_APP_TRANSACTION_PAID as string) + '?page=' + parseInt(pages?.current_page + 1),
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                }
            )
                .then(response => {
                    setTotalData({
                        ...totalData,
                        from: response.data.data.from,
                        to: response.data.data.to,
                        total: response.data.data.total
                    })
                    setPages(response.data.data);
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    return (
        <>
            <div className='text-2xl text-center font-bold'>Barang sudah dibayar</div><br />
            <Table data={pages?.data} />
            <div className='flex flex-col md:flex-row justify-between items-center'>
                <div className='mb-2 md:mb-0'>
                    <p>Showing {totalData.from} to {totalData.to} of {totalData.total} entries</p>
                </div>
                <div>
                    <div className="btn-group float-right mt-5">
                        <button className={pages.prev_page_url === null ? `btn btn-disabled` : `btn btn-primary`} onClick={prevPage}>«</button>
                        <button className="btn btn-primary">Page {pages?.current_page}</button>
                        <button className={pages.next_page_url === null ? `btn btn-disabled` : `btn btn-primary`} onClick={nextPage}>»</button>
                    </div>
                </div>
            </div>
        </>
    )
}
